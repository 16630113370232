import React from "react";
import './App.css';
import Header from "./Header";
import Banner from "./Banner";
import Footer from "./Footer";
import Galleries from "./Galleries";
import Portfolio from "./Portfolio";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutDetailedForm";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const promise = stripeKey ? loadStripe(stripeKey) : null;

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
        },
    ],
};

const TOKEN = process.env.REACT_APP_AUTHORIZATION; // Test

const videoStyle = {
    marginLeft: "20px",
    marginRight: "10px",
    width: "90%",
};
function App() {
    return (
        <main>
            {/* Start Header */}
            <Header/>
            {/* End Header */}

            {/* Promo Block */}
            <Banner/>
            {/* End Promo Block */}


            {/* Gallery */}
            <Galleries/>
            {/* End Gallery */}

            <section className="container-fluid g-px-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-md-6">
                        <div className="g-pa-5x" style={videoStyle}>
                            <iframe title="Video Youtube" width="100%" height="320"
                                    src="https://www.youtube.com/embed/yb648VCsNj0"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        </div>
                    </div>
                    <div className="col-md-6" id={"contribution"}>
                        <Elements stripe={promise} options={ELEMENTS_OPTIONS}>
                            <CheckoutForm token={TOKEN}/>
                        </Elements>
                    </div>
                </div>
            </section>

            {/* Cube Portfolio Blocks */}
            <Portfolio/>
            {/* End Cube Portfolio Blocks */}

            {/* Footer */}
            <Footer/>
            {/* End Footer */}

            <a className="js-go-to u-go-to-v1" href="/" data-type="fixed"
                data-position='{"bottom": 15, "right": 15}'
                data-offset-top="400" data-compensation="#js-header" data-show-effect="zoomIn">
                <i>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#72c02c" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"></path>
                    </svg>
                </i>
            </a>
        </main>
    );
}

export default App;
