import React from "react";

export default function top_navigation_menu(props) {
    return (
        <ul class="navbar-nav text-uppercase g-pos-rel g-font-weight-600 ml-auto">

            {/*** 
            <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                <a href="/#Perspectives" className="nav-link g-py-7 g-px-0">Perspectives</a>
            </li>

            <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                <a href="/" className="nav-link g-py-7 g-px-0">Beta</a>
            </li>

            <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                <a href="/" className="nav-link g-py-7 g-px-0">Gamma</a>
            </li>
            */}

            <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                <a className="btn u-btn-outline-primary g-font-size-12 g-py-10 g-px-15"
                    href="/#contribution">
                    <span>Pour soutenir le projet</span><br />
                    <span className="text-uppercase ">je fais un don</span>
                </a>
            </li>

        </ul>

    )
}