import React from "react";

export default function Portfolio(props) {
    return (

        <section className="container-fluid g-px-0">
            <div className="row no-gutters">
                <div className="col-md-4">
                    <div className="g-pa-15x">
                        <div className="mb-5">
                            <h3 className="h1 g-color-black mb-4"> Appui technique</h3>
                            <p>
                                <b>U</b>nité de <b>T</b>ransformation
                                <b>A</b>groalimentaire <b>M</b>usso <b>L</b>afia
                                &amp;  <br/>
                                <b>C</b>entre de <b>F</b>ormation
                                <b>B</b>akary <b>D</b>iallo,
                                <b>S</b>évaré, <b>M</b>opti</p>
                        </div>
                        <img className="img-fluid w-100" src="images/500x650/centre_bakari_00.jpg"
                             alt="logo Centre Bakary"/>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="g-pa-15x">
                        <div className="mb-5">
                            <h3 className="h1 g-color-black mb-4"> Appui technique</h3>
                            <p>
                                Direction Régionale de la Promotion <br/>
                                de la Femme de l'Enfant et de la Famille de <br/>
                                Mopti
                            </p>
                        </div>
                        <img className="img-fluid w-100 " src="images/500x650/mpfef.jpeg" alt="logo DRPFEF"/>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="g-pa-15x">
                        <div className="mb-5">
                            <h3 className="h1 g-color-black mb-4"> Appui technique</h3>
                            <p>
                                Ambassade de France au Mali
                            </p>
                        </div>
                        <img className="img-fluid w-100" src="images/500x650/amba_fr_ml_small.jpg"
                             alt="logo amba fr ml"/>
                    </div>
                </div>
            </div>
        </section>
    )
}