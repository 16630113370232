import React from "react";

export default function Banner(props) {
    return (
        
        <section className="g-bg-img-hero" style={{'backgroundImage': "url('images/1920x1080/banne1.jpg')"}}>
            <div className="container g-pos-rel g-z-index-1 g-py-150">
                <div className="g-mb-40" style={{ backgroundColor: '#72c02c'}}>
                    <h1 className="g-color-white g-font-weight-700 g-font-size-40 g-font-size-60--lg g-font-size-45--md g-line-height-1_2 mb-0">
                        Projet de formation en transformation agroalimentaire
                    </h1>
                    <span className="d-block g-color-white g-font-size-30 g-pos-rel g-top-minus-30--md ml-md-5">
                      à l’attention des femmes de Sévaré, dans le centre du Mali
                    </span>
                </div>

                {/*  backgroundImage ; background-image
                <div className="text-right">
                    <iframe title="Video Youtube" width="560" height="315" src="https://www.youtube.com/embed/yb648VCsNj0" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                </div> */}

            </div>
        </section>
    )
}