import React from "react";

export default function Footer(props) {
    return (
        <footer className="g-bg-bluegray">
            <div className="container g-pt-100 g-pb-60">
                <div className="row justify-content-between">
                    <p className="g-color-white-opacity-0_7 g-font-size-13">2021 &copy; CPD (Cellule Pour le Développement).</p>
                    <p className="g-color-white-opacity-0_7 g-font-size-13">Contact : <a href="mailto:cpd@cpd-mali.com">cpd@cpd-mali.com</a></p>
                    <p className="g-color-white-opacity-0_7 g-font-size-13">Adresse : 26000 Valence</p>
                    <p className="g-color-white-opacity-0_7 g-font-size-13">Téléphone : +33 6 61 47 03 08 / +223 71 32 62 56</p>
                </div>
            </div>
        </footer>
    )
}