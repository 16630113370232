import React from "react";
import TopNavigation from "./top_navigation_menu";
import logo from './logo.png';

export default function Header(props) {
    return (
        <header id="js-header" className="u-header u-header--static">
            <div className="u-header__section u-header__section--light g-bg-white g-transition-0_3 g-py-10">
                <nav className="js-mega-menu navbar navbar-expand-lg hs-menu-initialized hs-menu-horizontal">
                    <div className="container">
                        {/* Responsive Toggle Button */}
                        <button
                            className="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-minus-3 g-right-0"
                            type="button" aria-label="Toggle navigation" aria-expanded="false"
                            aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
                            <span className="hamburger hamburger--slider">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </span>
                        </button>
                        {/* End Responsive Toggle Button */}

                        {/* Logo */}
                        {/*a href="../../index.html" class="navbar-brand d-flex" */}

                        <a href="/">
                            <img src={logo} border="0" width="82" alt="logo" />
                        </a>

                        {/* End Logo */}

                        {/* Navigation */}
                        <div
                            className="collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg g-mr-40--lg"
                            id="navBar">
                            <TopNavigation />
                        </div>
                        {/* End Navigation */}

                    </div>
                </nav>
            </div>
        </header>
    )
}