import React from "react";

export default function Galleries(props) {
    return (
        <>
            <section className="container-fluid g-px-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-md-6">
                        <div className="g-pa-15x">
                            <div className="mb-5">
                                <h3 className="h1 g-color-black mb-4">Un pilier de l'économie</h3>
                                <li className="g-color-gray-dark-v4 g-font-size-14">
                                    Le secteur primaire (agriculture, pêche, élevage), pilier essentiel de l'économie
                                    malienne.
                                </li>
                                <br/>
                                <li className="g-color-gray-dark-v4 g-font-size-14">
                                    Un secteur qui emploie 7 actifs occupés sur 10 au Mali.​
                                </li>
                                <br/>
                                <li className="g-color-gray-dark-v4 g-font-size-14">
                                    <b>38%</b> du PIB national <i>(2017, BANQUE MONDIALE)</i>.​
                                </li>
                            </div>
                            {/*
                            <a class="btn u-btn-outline-primary g-font-weight-600 g-rounded-50 g-px-30 g-py-13" href="#">See more
                                <i class="ml-2 fa fa-long-arrow-right"></i></a> */}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="g-flex-centered g-bg-secondary text-center">
                            <div className="u-shadow-v19 g-bg-white g-pa-25">
                                <img className="img-fluid u-shadow-v21" src="images/400x270/img_gal_1.png"
                                     alt="Description"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Gallery */
            }

            {/* Gallery */
            }
            <section className="container-fluid g-px-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-md-6 order-md-2">
                        <div className="g-pa-15x">
                            <div className="mb-5">
                                <h3 className="h1 g-color-black mb-4">
                                    Un potentiel sous-exploité​
                                </h3>
                                <p className="g-color-gray-dark-v4 g-font-size-16">
                                    Les produits issus de l'agriculture, de la pêche et de l'élevage ne sont pas
                                    suffisamment
                                    valorisés en termes de transformations agro-alimentaires et sont généralement
                                    vendus sur les marchés nationaux et internationaux à l'état brut sans véritable
                                    valeur ajoutée. ​
                                </p>

                            </div>
                            {/*<a class="btn u-btn-outline-primary g-font-weight-600 g-rounded-50 g-px-30 g-py-13" href="#">See more
                                <i class="ml-2 fa fa-long-arrow-right"></i></a> */}
                        </div>
                    </div>

                    <div className="col-md-6 order-md-1">
                        <div className="g-flex-centered g-min-height-600 g-bg-secondary text-center">
                            <div className="u-shadow-v19 g-bg-white g-pa-25">
                                <img className="img-fluid u-shadow-v21" src="images/400x270/img_gal_2.png"
                                     alt="Description"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Gallery */
            }

            {/* Gallery : sous-exploitation*/}
            <section className="container-fluid g-px-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-md-6">
                        <div className="g-pa-15x">
                            <div className="mb-5">
                                <h3 className="h1 g-color-black mb-4">
                                    Une sous-exploitation qui nous coûte cher ...
                                </h3>
                                <li className="g-color-gray-dark-v4 g-font-size-16">
                                    Car le pays importe d’importantes quantités de ces mêmes produits alimentaires
                                    (très souvent disponibles à l’état brut dans le pays)
                                    transformés et emballés à l’étranger. ​
                                </li>
                                <br/>
                                <li className="g-color-gray-dark-v4 g-font-size-16">
                                    La sous-exploitation de ce secteur et du potentiel économique qu'il représente
                                    constitue un véritable
                                    manque à gagner pour un pays dont l'économie repose en grande partie sur le secteur
                                    agricole.
                                </li>
                            </div>
                            {/*<a class="btn u-btn-outline-primary g-font-weight-600 g-rounded-50 g-px-30 g-py-13" href="#">See more
                                <i class="ml-2 fa fa-long-arrow-right"></i></a>*/}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="g-flex-centered g-min-height-600 g-bg-secondary text-center">
                            <div className="u-shadow-v19 g-bg-white g-pa-25">
                                <img className="img-fluid u-shadow-v21" src="images/400x270/img_gal_3.jpg"
                                     alt="Description"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Gallery : valoriser et accompagner */}
            <section className="container-fluid g-px-0">
                <div className="row align-items-center no-gutters">

                    <div className="col-md-6">
                        <div className="g-flex-centered g-min-height-600 g-bg-secondary text-center">
                            <div className="u-shadow-v19 g-bg-white g-pa-25">
                                <img className="img-fluid u-shadow-v21" src="images/400x270/poisson_fumee.jpg"
                                    alt="Description" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="g-pa-15x">
                            <div className="mb-5" style={{ 'borderBlock': "1px solid #72c02c" }}>
                                <h3 className="h3 g-color-black mb-4">
                                    La CPD, pour valoriser et accompagner la transformation de nos produits agroalimentaires locaux
                                </h3>
                                <ul style={{ listStyleType: 'square' }}>
                                    <li className="g-color-gray-dark-v4 g-font-size-16">
                                        <b>Un projet de valorisation de :</b>
                                        <ul>
                                            <li> nos productions locales</li>
                                            <li> de la filière agro-alimentaire​</li>
                                        </ul>
                                    </li>

                                    <br />
                                    <li className="g-color-gray-dark-v4 g-font-size-16">
                                        <b>Un projet de développement socio-économique​:</b>
                                        <ul>
                                            <li> Renforcement des capacités opérationnelles des femmes​</li>
                                            <li> Epanouissement et autonomisation des femmes​</li>
                                            <li> Entrepreneuriat</li>
                                        </ul>
                                    </li>

                                    <br />
                                    <li className="g-color-gray-dark-v4 g-font-size-16">
                                        <b>Un projet de développement local durable:​</b>
                                        <ul>
                                            <li>Une implication des acteurs locaux (<b>U</b>nité de <b>T</b>ransformation <b>A</b>groalimentaire Bakary Diallo, les artisans locaux, les agriculteurs locaux).​</li>
                                            <li>Des productions locales valorisées</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* Gallery : sous-exploitation*/}
            <section className="container-fluid g-px-0"  id={"Perspectives"}>
                <div className="row align-items-center no-gutters">
                    <div className="col-md-6">
                        <div className="g-pa-15x">
                            <div className="mb-5">
                                <h3 className="h1 g-color-black mb-4"> Perspectives</h3>
                                <p className="g-color-gray-dark-v4 g-font-size-16">Elargir le partenariat et les collaborations pour:</p>
                                <ul>
                                    <li className="g-color-gray-dark-v4 g-font-size-16">
                                        Former plus de personnes notamment les femmes et les jeunes en s’appuyant sur les ressources locales.​ </li>
                                    <li className="g-color-gray-dark-v4 g-font-size-16">Elargir à d'autres territoires du pays.</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="g-flex-centered g-min-height-600 g-bg-secondary text-center">
                            <div className="u-shadow-v19 g-bg-white g-pa-25">
                                <img className="img-fluid u-shadow-v21" src="images/400x270/perspectives.jpg"
                                    alt="Description" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Gallery : sous-exploitation*/}
            <section className="container-fluid g-px-0"  id={"Perspectives"}>
                <div className="row align-items-center no-gutters">

                    <div className="col-md-6">
                        <div className="g-flex-centered g-min-height-600 g-bg-secondary text-center">
                            <div className="u-shadow-v19 g-bg-white g-pa-25">
                                <img className="img-fluid u-shadow-v21" src="images/400x270/chiffres.png"
                                     alt="Description" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="g-pa-15x">
                            <div className="mb-5">
                                <h3 className="h1 g-color-black mb-4">Quelques chiffres</h3>
                                <p className="g-color-gray-dark-v4 g-font-size-16">Un projet ambitieux :</p>
                                <ul>
                                    <li className="g-color-gray-dark-v4 g-font-size-16">
                                        Objectif visé former 50 femmes
                                    </li>
                                    <li className="g-color-gray-dark-v4 g-font-size-16">
                                        Première étape former 10 femmes (encours).
                                    </li>
                                    <li className="g-color-gray-dark-v4 g-font-size-16">
                                        315€ le coût estimé de la formation d'une candidate.
                                    </li>
                                    <li className="g-color-gray-dark-v4 g-font-size-16">
                                        Votre contribution est indispensable pour la réussite de ce projet.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}